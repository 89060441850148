import React from 'react';
import {Composition, Folder} from 'remotion';

import {defaultTalkValues} from '../../../../src/data/defaultValues';
import {ShowcaseSchema} from '../showcases.types';

import {DevfestNantes2024} from './2024/DevfestNantes2024';
import {DevfestNantesPhrase2024} from './2024/DevfestNantes2024Phrase';
import {DevfestNantesLoop2024} from './2024/DevfestNantesLoop2024';
import {DevfestNantesLoopTotem2024} from './2024/DevfestNantesLoopTotem2024';
import {ShowcaseDevfestNantes2024Schema} from './types/types';
import {DevfestNantes} from './DevfestNantes';
import {DevfestNantesLoop} from './DevfestNantesLoop';
import {DevfestNantesLoopTotem} from './DevfestNantesLoopTotem';
import {DevfestNantesPhrase} from './DevfestNantesPhrase';

export const DevfestNantesComposition = () => {
	return (
		<Folder name="DevfestNantes">
			<Folder name="DevfestNantes-2023">
				<Composition
					id="DevfestNantesTalk2023"
					component={DevfestNantes}
					durationInFrames={300}
					fps={30}
					width={1280}
					height={720}
					schema={ShowcaseSchema}
					defaultProps={defaultTalkValues}
				/>
				<Composition
					id="DevfestNantesTalkLoop2023"
					component={DevfestNantesLoop}
					durationInFrames={350}
					fps={30}
					width={1280}
					height={720}
					schema={ShowcaseSchema}
					defaultProps={defaultTalkValues}
				/>
				<Composition
					id="DevfestNantesTalkLoopTotem2023"
					component={DevfestNantesLoopTotem}
					durationInFrames={350}
					fps={30}
					width={720}
					height={1280}
					schema={ShowcaseSchema}
					defaultProps={defaultTalkValues}
				/>
				<Composition
					id="DevfestNantesPhrase2023"
					component={DevfestNantesPhrase}
					durationInFrames={350}
					fps={30}
					width={1280}
					height={720}
					defaultProps={defaultTalkValues}
				/>
			</Folder>
			<Folder name="DevfestNantes-2024">
				<Composition
					id="DevfestNantesTalk2024"
					component={DevfestNantes2024}
					durationInFrames={300}
					fps={30}
					width={1280}
					height={720}
					schema={ShowcaseDevfestNantes2024Schema}
					defaultProps={defaultTalkValues}
				/>
				<Composition
					id="DevfestNantesTalkLoop2024"
					component={DevfestNantesLoop2024}
					durationInFrames={350}
					fps={30}
					width={1280}
					height={720}
					schema={ShowcaseDevfestNantes2024Schema}
					defaultProps={defaultTalkValues}
				/>
				<Composition
					id="DevfestNantesTalkLoopTotem2024"
					component={DevfestNantesLoopTotem2024}
					durationInFrames={350}
					fps={30}
					width={720}
					height={1280}
					schema={ShowcaseDevfestNantes2024Schema}
					defaultProps={defaultTalkValues}
				/>
				<Composition
					id="DevfestNantesTalkPhrase2024"
					component={DevfestNantesPhrase2024}
					durationInFrames={350}
					fps={30}
					width={1280}
					height={720}
					schema={ShowcaseDevfestNantes2024Schema}
					defaultProps={defaultTalkValues}
				/>
			</Folder>
		</Folder>
	);
};

;
    var _remotion_globalVariableA, _remotion_globalVariableB;
    // Legacy CSS implementations will `eval` browser code in a Node.js context
    // to extract CSS. For backwards compatibility, we need to check we're in a
    // browser context before continuing.
    if (typeof self !== 'undefined' &&
        // AMP / No-JS mode does not inject these helpers:
        '$RefreshHelpers$' in self) {
        const currentExports = __webpack_module__.exports;
        const prevExports = (_remotion_globalVariableB = (_remotion_globalVariableA = __webpack_module__.hot.data) === null || _remotion_globalVariableA === void 0 ? void 0 : _remotion_globalVariableA.prevExports) !== null && _remotion_globalVariableB !== void 0 ? _remotion_globalVariableB : null;
        // This cannot happen in MainTemplate because the exports mismatch between
        // templating and execution.
        self.$RefreshHelpers$.registerExportsForReactRefresh(currentExports, __webpack_module__.id);
        // A module can be accepted automatically based on its exports, e.g. when
        // it is a Refresh Boundary.
        if (self.$RefreshHelpers$.isReactRefreshBoundary(currentExports)) {
            // Save the previous exports on update so we can compare the boundary
            // signatures.
            __webpack_module__.hot.dispose((data) => {
                data.prevExports = currentExports;
            });
            // Unconditionally accept an update to this module, we'll check if it's
            // still a Refresh Boundary later.
            __webpack_module__.hot.accept();
            // This field is set when the previous version of this module was a
            // Refresh Boundary, letting us know we need to check for invalidation or
            // enqueue an update.
            if (prevExports !== null) {
                // A boundary can become ineligible if its exports are incompatible
                // with the previous exports.
                //
                // For example, if you add/remove/change exports, we'll want to
                // re-execute the importing modules, and force those components to
                // re-render. Similarly, if you convert a class component to a
                // function, we want to invalidate the boundary.
                if (self.$RefreshHelpers$.shouldInvalidateReactRefreshBoundary(prevExports, currentExports)) {
                    __webpack_module__.hot.invalidate();
                }
                else {
                    self.$RefreshHelpers$.scheduleUpdate();
                }
            }
        }
        else {
            // Since we just executed the code for the module, it's possible that the
            // new exports made it ineligible for being a boundary.
            // We only care about the case when we were _previously_ a boundary,
            // because we already accepted this update (accidental side effect).
            const isNoLongerABoundary = prevExports !== null;
            if (isNoLongerABoundary) {
                __webpack_module__.hot.invalidate();
            }
        }
    }
